/** ----------------------------------------
    State
 ---------------------------------------- */

const state = {
    // sessionData -> User choices.
    // What we send back to the API
    sessionData: {
        order_id: null,
        order_no: null,
        order_type: null,
        name: '',
        email: '',
        product_type: 'sock',
        user_type: 1,
        lang: 'nl',
        age: 1,
        material: '',
        length: 1,
        size: 'S',
        street: '',
        house_no: 0,
        house_additional: '',
        postal: '0',
        city: '',
        imprint: '',
        newsletter: 0,
    },
    overlayVisible: false,
    overlayContent: '',
    colorChoices: [],
    selectedStripe: [],
    triggerUpdateCanvas: 0,
    triggerResetColors: 0,
    editorState: 0, // 0 = preview, 1 = editing
    preview: null,
    bmp: null,
    imprintBmp: null,
    timerRight: 0,
    popupContent: null,
    tooltip: {
        text: '',
        x: 0,
        y: 0,
        duration: 20000
    },
    tooltipInd: 0
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default state;
