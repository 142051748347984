import { colors, colorDefaults } from '@config';

/** ----------------------------------------
    Plugin
---------------------------------------- */

const AppPlugin = {
    install(Vue) {

        Vue.mixin({
            data() {
                return {
                    appColors: colors.colors,
                    appColorDefaults: colors.colorDefaults
                }
            },
            computed: {
            },
        });
    }
};

/** ----------------------------------------
    Exports
---------------------------------------- */

export default AppPlugin;