/** ----------------------------------------
    Interface
 ---------------------------------------- */

import { ToastStore } from '@interface/store/Toast';

/** ----------------------------------------
    State
 ---------------------------------------- */

const getters = {
    getToastData: state => state.data,
    getToastMessage: state => state.data.message,
    getToastButton: state => state.data.button,
    getToastLink: state => state.data.link
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default getters
