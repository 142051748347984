
/** ----------------------------------------
    App
 ---------------------------------------- */

/**
 * Stores all the primary app configuration
 * that is needed to keep processes running!
 */

const app: any = require('./app.config');

/** ----------------------------------------
    Local
 ---------------------------------------- */

/**
 * Stores data about the preferred local
 * presets of the application.
 */

const local: object = require('./local.config');

/** ----------------------------------------
    Bugherd
 ---------------------------------------- */

/**
 * Stores all the primary Bugherd configuration
 * that is needed to keep processes running!
 */

const bugherd: any = require('./bugherd.config');


const colors: any = require('./colors.config');

/** ----------------------------------------
     Module Export
 ---------------------------------------- */

module.exports = {
    app: app.app,
    local: local,
    bugherd: bugherd.inject,
    colors: colors
};