/** ----------------------------------------
    Imports
---------------------------------------- */

import mutations from '@store/mutations';
import state from '@store/state';
import axios from 'axios';
import { app } from '@config';
import { flare } from '@flareapp/flare-client';

/** ----------------------------------------
    Mutations
---------------------------------------- */

const mixins = {
    setData(key, value) {
        mutations.setSessionData(state, { 
            key: key, 
            value: value
        });
        this.putSessionData(state.sessionData.order_id, state.sessionData);
    },
    setDataAndRedirect(key, value, route) {
        this.setData(key, value);
        setTimeout(() => {
            this.$router.push(route)
        }, 300);
    },
    triggerOverlay(onOff) {
        mutations.toggleOverlay(state, onOff);
    },
    triggerTooltip(text, x, y, duration) {
        mutations.setTooltip(state, { text: text, x: x, y: y, duration: duration });
    },
    selectColor(color, stripes, choiceNo) {
        let lastSelectedStripe = state.selectedStripe[state.selectedStripe.length - 1]
        let oppositeChoice = choiceNo == 'second' ? 'first' : 'second';
        let oppositeColor = state.colorChoices[lastSelectedStripe][oppositeChoice];

        // Set selected color
        stripes.forEach(stripe => {
            mutations.setColorChoice(state, { color: color, stripe: stripe, choiceNo: choiceNo });
        });

        // For multiselect. Set also the opposite color to the rest of stripes
        stripes.forEach(stripe => {
            mutations.setColorChoice(state, { color: oppositeColor, stripe: stripe, choiceNo: oppositeChoice });
        });

        mutations.setUpdateCanvas(state);
    },
    setUpdateCanvas() {
        mutations.setUpdateCanvas(state);
    },
    selectStripe(i) {
        mutations.setSelectedStripe(state, i);
    },
    multiselectStripe(i) {
        mutations.addSelectedStripe(state, i);
    },
    savePreview(data) {
        mutations.setPreview(state, data);
    },
    saveBmp(data) {
        mutations.setBmp(state, data);
    },
    saveImprintBmp(data) {
        mutations.setImprintBmp(state, data);
    },
    saveEditorState(data) {
        mutations.setEditorState(state, data);
    },
    activeLang() {
        return state.sessionData.lang;
    },
    resetColors() {
        mutations.clearColorChoices(state);
        mutations.initColorChoices(state);
        mutations.setUpdateCanvas(state);
        mutations.setResetColors(state);
    },

    // Put correct content inside overlay, based on current route
    placeOverlayContent() {
        setTimeout(() => {
            let overlayContent = document.querySelector('.overlay-content');
            overlayContent && mutations.setOverlayContent(state, overlayContent.innerHTML);
        }, 10);
    },

    // COMB SOCK
    // This function makes sure there are no double sripes by swapping colors if they repeat.
    combSock(colors) {
        for (let i = 0; i < colors.length - 1; i++) {
            let firstCurrent = colors[i].first;
            let secondCurrent = colors[i].second;
            let firstNext = colors[i + 1].first;
            let secondNext = colors[i + 1].second;
            if (firstCurrent == secondNext || secondCurrent == firstNext) {
                colors[i + 1].first = secondNext;
                colors[i + 1].second = firstNext;
            }
        }
    },

    /** ----------------------------------------
        API CALLS
    ---------------------------------------- */

    // GET ORDER ID
    getOrderId(productType) {
        axios.post(app.api.api + 'order', {
            product_type: productType
        }, {
            headers: {
                Authorization: 'Bearer test'
            }
        }).then(function (response) {
            mutations.setSessionData(state, { key: 'order_id', value: response.data['id']})
            mutations.setSessionData(state, { key: 'order_no', value: response.data['order_no']})
            mutations.setSessionData(state, { key: 'order_type', value: productType})
        }).catch(function (error) {
            flare.report(error);
        });
    },

    // GET POPUP CONTENT
    getPopupContent() {
        axios.get(app.api.api + 'templates', {
            'headers': { 'Authorization': 'Bearer test' } 
        }).then(response => {
            mutations.setPopupContent(state, response.data);
            this.placeOverlayContent();
        }).catch(function (error) {
            flare.report(error);
        });
    },

    // POST SOCK/SCARF BMPS
    postImages(orderId, data) {
        const res = axios.post(app.api.api + `file/${orderId}`, data, {
            headers: {
                Authorization: 'Bearer test'
            }
        }).then(function (response) {
            console.log(response);
        }).catch(function (error) {
            flare.report(error);
        });
        
        console.log(res, data);
    },

    // PUT SESSION DATA
    putSessionData(orderId, data) {
        axios.put(app.api.api + `order/${orderId}`, data, {
            headers: {
                Authorization: 'Bearer test'
            }
        }).then(function (response) {
            console.log(response);
        }).catch(function (error) {
            flare.report(error);
        }); 
    }
};

/** ----------------------------------------
    Export Mutations
---------------------------------------- */

export default mixins;
