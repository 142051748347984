/** ----------------------------------------
    Imports
---------------------------------------- */

import { AppStore } from "@interface/store/App";
import { colors } from '@config';

/** ----------------------------------------
    Mutations
---------------------------------------- */

const mutations = {
  toggleOverlay(state, onOff) {
    state.overlayVisible = onOff;
  },
  setOverlayContent(state, data) {
    state.overlayContent = data;
  },
  setSessionData(state, data) {
    state.sessionData[data.key] = data.value;
  },
  setTimerRight(state, data) {
    state.timerRight = data;
  },
  clearColorChoices(state) {
    state.colorChoices = [];
  },
  initColorChoices(state) {

    let colorOptions = 0;

    if (state.sessionData.product_type == 'sock') {
        // sock length
        state.sessionData['length'] == 1 && (colorOptions += 2);
        state.sessionData['length'] == 2 && (colorOptions += 8);
        state.sessionData['length'] == 3 && (colorOptions += 11);
        // sock size
        state.sessionData['size'] == 'S' && (colorOptions += 6);
        state.sessionData['size'] == 'M' && (colorOptions += 7);
        state.sessionData['size'] == 'L' && (colorOptions += 8);
        state.sessionData['size'] == 'XL' && (colorOptions += 9);
    } else if (state.sessionData.product_type == 'scarf') {
        // scarf length
        state.sessionData['size'] == 'R' && (colorOptions += 42);
        state.sessionData['size'] == 'L' && (colorOptions += 42);
    }

    colors.colorDefaults[state.sessionData.product_type][0]
    for (let i = 0; i < colorOptions; i++) {
      let colorObj = new Object;
      colorObj.first = colors.colorDefaults[state.sessionData.product_type][0];
      colorObj.second = colors.colorDefaults[state.sessionData.product_type][1];
      state.colorChoices.push(colorObj)
    }
  },
  setColorChoice(state, data) {
    state.colorChoices[data.stripe][data.choiceNo] = data.color
  },
  setSelectedStripe(state, data) {
    if (data >= 0) {
      state.selectedStripe = [data];
    } else {
      state.selectedStripe = [];
    }
  },
  // for multi select
  addSelectedStripe(state, data) {
    let indOf = state.selectedStripe.indexOf(data);
    if (indOf < 0) {
      state.selectedStripe.push(data);
    } else {
      state.selectedStripe.splice(indOf, 1);
    }
  },
  setUpdateCanvas(state) {
    state.triggerUpdateCanvas++;
  },
  setResetColors(state) {
    state.triggerResetColors++;
  },
  setPreview(state, data) {
    state.preview = data;
  },
  setBmp(state, data) {
    state.bmp = data;
  },
  setImprintBmp(state, data) {
    state.imprintBmp = data;
  },
  setEditorState(state, data) {
    state.editorState = data;
  },
  setTooltip(state, data) {
    state.tooltip.text = data.text;
    state.tooltip.x = data.x;
    state.tooltip.y = data.y;
    state.tooltip.duration = data.duration;
    state.tooltipInd++;
  },
  setPopupContent(state, data) {
    state.popupContent = data
  }
};

/** ----------------------------------------
    Export Mutations
---------------------------------------- */

export default mutations;
