
 /** ----------------------------------------
     Naming
  ---------------------------------------- */
 
 export class Naming {
    toSnakeCase(string) {
        var s;
        s = string.replace(/[^\w\s]/g, "");
        s = s.replace(/\s+/g, " ");
        return s.toLowerCase().split(' ').join('_');
    }

    toPascalCase(string) {
        return `${string}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
        new RegExp(/\s+(.)(\w+)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`)
                .replace(new RegExp(/\s/, 'g'), '')
                .replace(new RegExp(/\w/), s => s.toUpperCase());
    }
 }