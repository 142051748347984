
























/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Mixins, Prop, Emit, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
    props: {
      active: {
        type: Boolean,
        required: false,
        default: true
      }
    },
    data(){
      return {
        clicking: false,
        dragDistance: 0,
        dragThreshold: 1
      }
    },
    methods: {
        startClicking(){
          this.clicking = true;
        },
        stopClicking(e){
          if (!this.active) { return }
          if (!this.clicking) { return }
          this.clicking = false;


          // if not dragging re-route
          if (this.dragDistance < this.dragThreshold) {
            
            //if command / control + click open in new tab
            if (e.ctrlKey || e.metaKey) {
              window.open(this.to,'_blank');
              return;
            }
            this.$router.push(this.to)
          }
          
          this.dragDistance = 0;
        },
        drag(event) {
          if (this.clicking) {
            this.dragDistance++;
          }
        },
        rightClick() {
          this.clicking = false;
        }
    }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class NoDragLink extends Vue {
  @Prop() to!: string;

  @Prop(Boolean) prevent: boolean;

  @Prop(Boolean) disabled: boolean;
}
