/** ----------------------------------------
    Imports
---------------------------------------- */

import * as components from './components';
import * as filters from './filters';
import * as helpers from './helpers';
import * as mixins from './mixins';
import * as services from './services';

/** ----------------------------------------
    Functions
---------------------------------------- */

/**
 * Get items to register
 * 
 * @param {Object} config // config
 * @param {string} configOption // config option
 * @param {Object} items // all items
 * @param {Boolean} registerItemsOnEmptyConfig // all items
 * 
 * @returns {Object} // items to resiter
 */
function getRegisterItems(...args) {
    const [config, configOption, items, registerItemsOnEmptyConfig = true] = args;

    let result = {};

    if (!config[configOption]) {
        if (registerItemsOnEmptyConfig)
            result = { ...items };
    } else {
        config[configOption].forEach(key => {
            if (items[key])
                result[key] = items[key];
        });
    }

    return result;
};

/** ----------------------------------------
    Plugin
---------------------------------------- */

const FruitPackage = {
    install(Vue, config = {}) {
        
        // Set components
        const registerComponents = getRegisterItems(config, 'components', components);
        Vue.mixin({
            components: registerComponents
        });

        // Set filters
        const registerFilters = getRegisterItems(config, 'filters', filters);
        Object.keys(registerFilters).forEach(key => {
            Vue.filter(registerFilters[key].name, registerFilters[key].fn);
        });

        // Set filters
        const registerMixins = getRegisterItems(config, 'mixins', mixins, false);
        Object.keys(registerMixins).forEach(key => {
            Vue.mixin(registerMixins[key].options);
        });
    }
};

/** ----------------------------------------
    Exports
---------------------------------------- */

export default FruitPackage;
export { components, filters, helpers, mixins, services };