







/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        computed: {
            cClass() {
                let classes = ['vimeo'];

                return classes.join(' ');
            },
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Vimeo extends Vue{};
