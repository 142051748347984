import { Naming } from '@helpers/NamingConventions';
import errorsRoutes from "@router/routes/errors";
const naming = new Naming();

/** ----------------------------------------
    Front routes
 ---------------------------------------- */


const routes = [
    {
        path: '',
        redirect: '/home',
        component: () => import('@view/layout/Master.vue'),
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import('@view/templates/Home.vue'),
            }
        ],
    }
];

const steps = [
    'login',
    'settings',
    'language',
    'material',
    'length',
    'size',
    'imprint',
    'ready',
    'design',
    'shipping',
    'age',
    'finish'
];

// push all steps to routes
steps.forEach(step => {
    routes[0].children.push({
        path: `/${naming.toSnakeCase(step)}`,
        name: naming.toSnakeCase(step),
        component: () => import(`@view/templates/steps/${naming.toPascalCase(step)}.vue`),
    });
})

/** ----------------------------------------
    Export Modules
 ---------------------------------------- */

export default routes;



