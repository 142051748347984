/** ----------------------------------------
    Store
 ---------------------------------------- */

import Vue from 'vue';
import Vuex from 'vuex';

import routesModule from '@store/routes/module';
import pagesModule from '@store/pages/module';
import settingsModule from '@store/settings/module';
import applicationModule from '@store/application/module';
import toastModule from '@store/toast/module';

import state from './state';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

/** ----------------------------------------
    Initiate Vuex
 ---------------------------------------- */

Vue.use(Vuex);

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default new Vuex.Store({
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
    modules: {
        routes: routesModule,
        pages: pagesModule,
        settings: settingsModule,
        application: applicationModule,
        toast: toastModule
    }
});