







/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({})

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class FormLabel extends Vue {
        @Prop() target!: string;
}

