





















































/* ----------------------------------------
        Mixins
    ---------------------------------------- */

import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { FormMixin, FormInputMixin, FormInputTypeMixin, SlotsMixin } from '../../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        mixins: [
            FormMixin, 
            FormInputMixin,
            FormInputTypeMixin,
            SlotsMixin
        ],

        computed: {
            cClass() {
                let classes = [this.mainClass];

                classes.push('form-' + this.cType);

                if (this.error)
                    classes.push('has-error');

                if (!this.value)
                    classes.push('has-novalue');

                if (this.value)
                    classes.push('has-value');

                if (this.prefix)
                    classes.push('has-prefix');

                if (this.postfix)
                    classes.push('has-postfix');

                if (this.insetLabel && this.topLabel)
                    classes.push('has-outside-label');

                if (this.insetLabel && !this.topLabel)
                    classes.push('has-inside-label');

                return classes.join(' ');
            }
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class FormInputTest extends Vue {
        @Prop() prefix!: string;

        @Prop() postfix!: string;

        @Prop() insetLabel!: string;
}

