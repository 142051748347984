







/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Inject, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                active: false,
            };
        },

        computed: {
            cClass() {
                let classes = ['tab'];

                return classes.join(' ');
            },

            cLinkClass() {
                let classes = [this.linkClass];

                if (this.active)
                    classes.push('is-active');

                if (this.disabled)
                    classes.push('is-disabled');

                return classes.join(' ');
            },

            _isTab() {
                return true;
            }
        },
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Tab extends Vue{
        @Prop() id!: string;

        @Prop() title!: string;

        @Prop(Boolean) disabled!: boolean;

        @Inject() readonly linkClass!: string
};
