










/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({

        computed: {
            progressPercentage() {
                return parseFloat(this.progress) * 100 + '%';
            },

            progressWidth() {
                return `width: ${ parseFloat(this.progress) * 100 }%;`;
            },

            cClass() {
                return `progress-bar progress-bar--text-${this.textPosition}`;
            },
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class ProgressBar extends Vue {
        @Prop({ type: Number, default: 0 }) progress!: number;

        @Prop({ default: 'after' }) textPosition!: string;

        @Prop(Boolean) showText!: boolean;
}

