/** ----------------------------------------
    Interface
 ---------------------------------------- */

import { ToastStore } from '@interface/store/Toast';

/** ----------------------------------------
    Mutations
 ---------------------------------------- */

const mutations = {
    setToastData(state: ToastStore, data: object) {
        state.data = data;
    },
    setToastMessage(state: ToastStore, data: object) {
        if(state.data.message = data) {
            state.data.message = state.data.message + `<span style="font-size: 0px !important;">${Math.random()*100}</span>`;
        } else {
            state.data.message = data;
        }
    },
    setToastButton(state: ToastStore, data: object) {
        state.data.button = data;
    },
    setToastLink(state: ToastStore, data: object) {
        state.data.link = data;
    },
    setToastColor(state: ToastStore, data: object) {
        state.data.color = data;
    },
};

/** ----------------------------------------
    Export Mutations
 ---------------------------------------- */

export default mutations;
