












/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Mixins, Prop, Emit, Vue } from 'vue-property-decorator';
import { SlotsMixin } from './../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

@Component({
    mixins: [SlotsMixin],

    computed: {
        cClass() {
            let classes = ['btn'];

            if (this.disabled) classes.push('is-disabled');

            return classes.join(' ');
        }
    },

    methods: {
        click(event) {
            if (this.prevent || this.disabled) {
                this.$emit('click', event, this);
                return;
            }

            this.$router.push(this.to);
        }
    }
})

/* ----------------------------------------
        Export
    ---------------------------------------- */
export default class RouterButton extends Vue {
  @Prop() to!: string;

  @Prop(Boolean) prevent: boolean;

  @Prop(Boolean) disabled: boolean;
}
