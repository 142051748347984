









































/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import { FormMixin, FormInputMixin, FormInputTypeMixin, FormInputFileMixin, SlotsMixin } from '../mixins';
import { NumberHelper } from '../helpers';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                oldValueProxy: null,
                size: null,
                files: null,
                formData: null,
            };
        },

        mixins: [
            SlotsMixin,
        ],

        created() {
            this.oldValueProxy = this.oldValue;
        },

        computed: {
            labelText() {
                if (!this.value || !this.files) {
                    return this.oldValueProxy;
                } 
                if (this.multiple &&  this.files.length > 1) {
                    return `${ this.files.length } bestanden`;
                } 
                return this.files[0].name;
                
            },

            hasValue() {
                return (this.oldValueProxy || this.value);
            },
        },

        methods: {
            input(e) {
                if (!e.target.files) return;

                this.files = e.target.files;

                this.setFormData(e);
                this.setSize(e);
            },

            setFormData(e) {
                const formData = new FormData();

                Array
                    .from(Array(this.files.length).keys())
                    .map(x => {
                        formData.append('name', this.files[x], this.files[x].name);
                    });

                this.formData = formData;
            },

            setSize(e) {
                let size = 0;
                Array.from(this.files).forEach(file => size += file.size);
                this.size = NumberHelper.formatBytes(size, 0);
            },

            reset() {
                this.files = null;
                this.formData = null;
                this.size = null;
                this.oldValueProxy = null;
                this.$refs.input.value = '';
                this.$emit('reset');
            }
        },

        watch: {
            files() {
                this.$emit('input', this.files);
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class Test extends Vue {
        @Prop() value!: string;

        @Prop() oldValue!: string;

        @Prop() accept!: string|array;

        @Prop() acceptText!: string;

        @Prop({ default: 'remove' }) removeText!: string;

        @Prop(Boolean) multiple!: boolean;

        @Prop(Boolean) showSize!: boolean;
}

