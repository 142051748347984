






/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    data() {
        return {
        };
    },
    components: {
    },
    props: {
    },
    methods: {
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Slider extends Vue {}
