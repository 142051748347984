/* ----------------------------------------
    Bugherd Configuration
---------------------------------------- */

const bugherd = {
    active: true,
    key: 'lex1c4ekpifoebkntpt5ma'
};

const inject = () => {
    if(!bugherd.active) return;
    (function (d, t) {
        var bh = d.createElement(t), s = d.getElementsByTagName(t)[0];
        bh.type = 'text/javascript';
        bh.src = 'https://www.bugherd.com/sidebarv2.js?apikey=' + bugherd.key;
        s.parentNode.insertBefore(bh, s);
    })(document, 'script');
};

/* ----------------------------------------
    Export Module
---------------------------------------- */

module.exports = {
    bugherd,
    inject
};