







/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        computed: {
            cClass() {
                const classes = ['btn-group'];

                if (this.justify)
                    classes.push(`btn-group--${ this.justify }`);

                return classes.join(' ');
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class ButtonGroup extends Vue {
        @Prop() justify!: string
};
    
