/** ----------------------------------------
    Imports
 ---------------------------------------- */

const data = {
    message: '',
    button: '',
    link: '/nowhere'
};

/** ----------------------------------------
     State
 ---------------------------------------- */

const state = () => {
    return {
        data: data
    }
};

/** ----------------------------------------
    Export State
 ---------------------------------------- */

export default state;