
















/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import { SlotsMixin } from './../../mixins';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({
        data() {
            return {
                clicked: false,
            };
        },

        mixins: [
            SlotsMixin, 
        ],

        computed: {
            cClass() {
                let classes = ['form-button', 'btn'];

                return classes.join(' ');
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class FormButton extends Vue {
        @Prop(Boolean) prevent: boolean;

        @Emit()
        click(event) {
            this.clicked = true;

            if (this.prevent)
                event.preventDefault();
            
        }
}

