/* ----------------------------------------
    App Environment
---------------------------------------- */

const isDev = process.env.NODE_ENV === 'development';
const isProd = process.env.NODE_ENV === 'production';

/* ----------------------------------------
    App Configuration
---------------------------------------- */

const domains = ['textielmuseum.ddev.site', 'staging.museumnacht.amsterdam'];
const use_env_url = domains.includes(window.location.hostname);

const app = {
  analytics: {
    ANONYMIZED: false,
    TRACKING_ID: "UA-68534440-1"
  },
  dev: {
      env: {
        type: process.env.NODE_ENV,
        dev: isDev,
        prod: isProd,
      },
    GLOBAL_COMPONENTS: "./../components/globals"
  },
  api: {
    api: process.env.API_URL || window.location.origin + '/api/'
    // READ_KEY: process.env.READ_KEY || '',
    // WRITE_KEY: process.env.WRITE_KEY || '',
  }
};

/* ----------------------------------------
    Export Module
---------------------------------------- */

export {
  app
};
