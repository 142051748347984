/* ----------------------------------------
    Color Configuration
---------------------------------------- */

const colors = {
  sock: [
    {
      name: { en: 'Grey', nl: 'Grijs'},
      color: '#aaa6a4',
      text: 'black',
      sockCode: '#00a800',
    },{
      name: { en: 'Blue', nl: 'Blauw'},
      color: '#52ace1',
      text: 'black',
      sockCode: '#0000aa',
    },{
      name: { en: 'Black', nl: 'Zwart'},
      color: '#474543',
      text: 'white',
      sockCode: '#dcdcdc',
    },{
      name: { en: 'Green', nl: 'Groen'},
      // color: '#90bf21',
      color: '#08e800',
      text: 'black',
        sockCode: '#aa0000',
    },{
      name: { en: 'Yellow', nl: 'Geel'},
      color: '#ffeb00',
      text: 'black',
      sockCode: '#64dc64',
    },{
      name: { en: 'Pink', nl: 'Roze'},
      color: '#ea5584',
      text: 'black',
      sockCode: '#dcb464',
    }
  ],
  scarf: [
    {
      name: { en: 'Blue', nl: 'Blauw' },
      color: '#52ace1',
      text: 'black',
    },{
      name: { en: 'Green-grey', nl: 'Groengrijs' },
      color: '#aaa6a4',
      text: 'white',
    },{
      name: { en: 'Pink', nl: 'Roze' },
      color: '#e167a3',
      text: 'black',
  },{
      name: { en: 'Yellow', nl: 'Geel' },
      color: '#ffeb00',
      text: 'black',
    },{
      name: { en: 'Grey', nl: 'Grijs' },
      color: '#f1f5f7',
      text: 'black',
    },{
      name: { en: 'Red', nl: 'Rood' },
      color: '#d70f28',
      text: 'black',
    },{
      name: { en: 'Black', nl: 'Zwart' },
      color: '#23201d',
      text: 'white',
    },{
      name: { en: 'Green', nl: 'Groen' },
      color: '#90bf21',
      text: 'black',
    }
  ],
  scarfCombinations: [
      // MIX,          BMP BG             BMP FG (LETTER)    ACTUAL BG      ACTUAL FG (LETTER)
      {mix: '#57aad4', bgCode: '#009191', fgCode: '#008040', bg: '#52ace1', fg: '#aaa6a4'}, // BLUE BG / GREY FG
      {mix: '#8a8bc3', bgCode: '#00c8c8', fgCode: '#008000', bg: '#52ace1', fg: '#e167a3'}, // BLUE BG / PINK FG
      {mix: '#90c574', bgCode: '#0e7c6f', fgCode: '#ff8000', bg: '#52ace1', fg: '#ffeb00'}, // BLUE BG / YELLOW FG
      {mix: '#8cd2f5', bgCode: '#beffff', fgCode: '#800000', bg: '#52ace1', fg: '#f1f5f7'}, // BLUE BG / LIGHTGREY FG
      {mix: '#725995', bgCode: '#919100', fgCode: '#ff0080', bg: '#52ace1', fg: '#d70f28'}, // BLUE BG / RED FG
      {mix: '#0e608b', bgCode: '#c8c800', fgCode: '#82ca80', bg: '#52ace1', fg: '#23201d'}, // BLUE BG / BLACK FG
      {mix: '#57b576', bgCode: '#d9d171', fgCode: '#8080ff', bg: '#52ace1', fg: '#90bf21'}, // BLUE BG / GREEN FG
      {mix: '#d483b5', bgCode: '#ffffd2', fgCode: '#f89452', bg: '#aaa6a4', fg: '#e167a3'}, // GREY BG / PINK FG
      {mix: '#d7cb41', bgCode: '#780078', fgCode: '#008080', bg: '#aaa6a4', fg: '#ffeb00'}, // GREY BG / YELLOW FG
      {mix: '#d0d0d1', bgCode: '#b400b4', fgCode: '#00ff00', bg: '#aaa6a4', fg: '#f1f5f7'}, // GREY BG / LIGHTGREY FG
      {mix: '#c24e65', bgCode: '#8f9cba', fgCode: '#ff8040', bg: '#aaa6a4', fg: '#d70f28'}, // GREY BG / RED FG
      {mix: '#635f5e', bgCode: '#ffafff', fgCode: '#804040', bg: '#aaa6a4', fg: '#23201d'}, // GREY BG / BLACK FG
      {mix: '#98bd3f', bgCode: '#000078', fgCode: '#ff00ff', bg: '#aaa6a4', fg: '#90bf21'}, // GREY BG / GREEN FG
      {mix: '#f7ac61', bgCode: '#0000b4', fgCode: '#8080c0', bg: '#e167a3', fg: '#ffeb00'}, // PINK BG / YELLOW FG
      {mix: '#ebb2d2', bgCode: '#993c5c', fgCode: '#0080c0', bg: '#e167a3', fg: '#f1f5f7'}, // PINK BG / LIGHTGREY FG
      {mix: '#e62a75', bgCode: '#a0a0ff', fgCode: '#00ffff', bg: '#e167a3', fg: '#d70f28'}, // PINK BG / RED FG
      {mix: '#91386c', bgCode: '#009100', fgCode: '#00ff40', bg: '#e167a3', fg: '#23201d'}, // PINK BG / BLACK FG
      {mix: '#c6a361', bgCode: '#00c800', fgCode: '#80ff00', bg: '#e167a3', fg: '#90bf21'}, // PINK BG / GREEN FG
      {mix: '#f7ed76', bgCode: '#e95eec', fgCode: '#ffff00', bg: '#ffeb00', fg: '#f1f5f7'}, // YELLOW BG / LIGHTGREY FG
      {mix: '#ea7a04', bgCode: '#c3ffc3', fgCode: '#efd95a', bg: '#ffeb00', fg: '#d70f28'}, // YELLOW BG / RED FG
      {mix: '#938922', bgCode: '#910000', fgCode: '#ff80ff', bg: '#ffeb00', fg: '#23201d'}, // YELLOW BG / BLACK FG
      {mix: '#c7d300', bgCode: '#c80000', fgCode: '#ff80c0', bg: '#ffeb00', fg: '#90bf21'}, // YELLOW BG / GREEN FG
      {mix: '#e37d94', bgCode: '#f455b9', fgCode: '#0080ff', bg: '#f1f5f7', fg: '#d70f28'}, // LIGHTGREY BG / RED FG
      {mix: '#8a8a8a', bgCode: '#ffa0a0', fgCode: '#80ffff', bg: '#f1f5f7', fg: '#23201d'}, // LIGHTGREY BG / BLACK FG
      {mix: '#c2d774', bgCode: '#964b00', fgCode: '#00ff80', bg: '#f1f5f7', fg: '#90bf21'}, // LIGHTGREY BG / GREEN FG
      {mix: '#801623', bgCode: '#c86400', fgCode: '#80ff80', bg: '#d70f28', fg: '#23201d'}, // RED BG / BLACK FG
      {mix: '#b37518', bgCode: '#fa7d00', fgCode: '#ffff80', bg: '#d70f28', fg: '#90bf21'}, // RED BG / GREEN FG
      {mix: '#4e7b2e', bgCode: '#ffc896', fgCode: '#ff8080', bg: '#23201d', fg: '#90bf21'}, // BLACK BG / GREEN FG
  ]
};

const colorDefaults = {
  sock: [
    colors.sock[0],
    colors.sock[2],
  ],
  scarf: [
    colors.scarf[1],
    colors.scarf[4],
  ]
}

/* ----------------------------------------
    Export Module
---------------------------------------- */

export { colors, colorDefaults };
