

































































/* ----------------------------------------
        Imports
    ---------------------------------------- */

import { Component, Mixins, Prop, Vue } from 'vue-property-decorator';
import { FormMixin, FormInputMixin, FormInputTypeMixin, SlotsMixin } from './../../mixins';
import { NumberHelper } from './../../helpers';

/* ----------------------------------------
        Component
    ---------------------------------------- */

    @Component({

        data() {
            return {
                size: null,
                files: null,
                formData: null,
            };
        },

        mixins: [
            FormMixin, 
            FormInputMixin,
            FormInputTypeMixin,
            SlotsMixin
        ],

        computed: {
            oldValueProxy: {
                get () {
                    return this.oldValue;
                },
                set (value) {
                    return value;
                }
            },

            cClass() {
                let classes = [this.mainClass];

                classes.push('form-' + this.cType);

                if (this.error)
                    classes.push('has-error');

                if (this.disabled)
                    classes.push('is-disabled');

                if (!this.value && !this.oldValueProxy)
                    classes.push('has-novalue');

                if (this.value || this.oldValueProxy)
                    classes.push('has-value');

                return classes.join(' ');
            },

            labelText() {
                if (!this.value || !this.files) {
                    return this.oldValueProxy;
                } 
                if (this.multiple &&  this.files.length > 1) {
                    return `${ this.files.length } bestanden`;
                } 
                return this.files[0].name;
                
            },

            hasValue() {
                return (this.oldValueProxy || this.value);
            },
        },

        methods: {
            onInput(e) {
                if (!e.target.files) return;

                this.files = e.target.files;

                this.setFormData(e);
                this.setSize(e);
                this.$emit('input', this.files);
            },

            setFormData(e) {
                const formData = new FormData();

                Array
                    .from(Array(this.files.length).keys())
                    .map(x => {
                        formData.append(this.name, this.files[x], this.files[x].name);
                    });

                this.formData = formData;
            },

            setSize(e) {
                let size = 0;
                Array.from(this.files).forEach(file => size += file.size);
                this.size = NumberHelper.formatBytes(size, 0);
            },

            onClear(e) {
                this.files = null;
                this.formData = null;
                this.size = null;
                this.$refs.input.value = '';
                this.$emit('clear', null);
            },

            onClick(e) {
                if (!this.hasValue || !this.clearOnClick) return;

                e.preventDefault();
                this.onClear();
            }
        },

        watch: {
            files() {
                this.$emit('input', this.files);
            }
        }
    })

/* ----------------------------------------
        Export
    ---------------------------------------- */

export default class FormInputFile extends Vue {
        @Prop() oldValue!: string;

        @Prop() accept!: string|array;

        @Prop() acceptText!: string;

        @Prop({ default: 'remove' }) removeText!: string;

        @Prop(Boolean) multiple!: boolean;

        @Prop(Boolean) showSize!: boolean;

        @Prop(Boolean) clearOnClick!: boolean;
}

