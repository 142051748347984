
/* -------------------------------------------------->8-------------

                                ,!!!!, 
                            ,!!!!!!!!!, 
                          ,!!!!!!!!!!!, 
                      ,!!!!!!!!!!!!!,  .  
                    ,!!!!!!!!!,        . 
                    ,!!!!,              . 
                      ,                  . 
                        .                 . 
                         .                 . 
                          .                .. 
                           .             ..... 
                            .           ....... 
                             .          ......... 
                             .            ........ 
                            .            ......... 
                           .            ......... 
                         .                 .... 
                       ...                  . 
                      .....                . 
                     .......              . 
                    ..........           . 
                    ............        . 
                     .............     . 
                      ..............  . 
                        ,...........,
                         ,......... 


               ________  ___   _  __    _    ___    __
              /_  __/  |/  /  | |/ /   | |  / / |  / /
               / / / /|_/ /   |   /    | | / /| | / / 
              / / / /  / /   /   |     | |/ / | |/ /  
             /_/ /_/  /_/   /_/|_|     |___/  |___/   
                                         
 
                                                                    
----------------8<----------------------------------------------- */



/* ----------------------------------------
    Vue
---------------------------------------- */

import Vue from 'vue';
import App from '@/App.vue';
import router from '@router/router';
import store from '@store/store';
import { app } from '@config';
import mixins from '@util/mixins';

/* ----------------------------------------
    Package
---------------------------------------- */

import VueAnalytics from 'vue-analytics';
import FruitPackage from '@fruitpackage';
import AppPlugin from '@plugins/app';

/* ----------------------------------------
    Vue Configuration
---------------------------------------- */

Vue.config.productionTip = false;
Vue.config.devtools = true;

/* ----------------------------------------
    Vue Plugins
---------------------------------------- */

Vue.use(FruitPackage);
Vue.use(AppPlugin);

/* ----------------------------------------
    Vue mixins
---------------------------------------- */

Vue.mixin({
    methods: mixins
})

/* ----------------------------------------
    Vue Analytics
---------------------------------------- */

const ga = app.analytics;

Vue.use(VueAnalytics, {
    id: ga.TRACKING_ID,
    router,
    disable: true,
    debug: {
        enabled: app.dev.env.dev && app.analytics.debug,
        sendHitTask: app.dev.env.prod
    },
    beforeFirstHit () {
        window[`ga-disable-${app.analytics.TRACKING_ID}`] = true;
      },
      ready () {
        window[`ga-disable-${app.analytics.TRACKING_ID}`] = true;
      }
});

/* ----------------------------------------
    Vue Data
---------------------------------------- */

const env = {
    type: process.env.NODE_ENV,
    scheme: process.env.VUE_APP_SCHEME,
    host: process.env.VUE_APP_HOST,
    port: process.env.VUE_APP_PORT,
};

env.baseUrl = (env.port) ? `${ env.scheme }${ env.host }:${ env.port}` : env.host;

const path = {
    start: window.location.pathname
};

const log = {
    type: process.env.LOGNAME
};

/* ----------------------------------------
    Flare logging
---------------------------------------- */

import { flare } from '@flareapp/flare-client';
import { flareVue } from "@flareapp/flare-vue";

if (process.env.NODE_ENV === 'production') {
    flare.light('jY1rGPVfEcQqqPu6GYto25CQg7ygUpaf');

    Vue.use(flareVue);
}

/* ----------------------------------------
    Vue Events
---------------------------------------- */

import './events/keys';

/* ----------------------------------------
    Vue Filters
---------------------------------------- */

import './filters/filters';

/* ----------------------------------------
    Initiate Vue
---------------------------------------- */

new Vue({
    el: '#app',
    data: {
        path: path,
        env: env,
        log: log,
        public: true,
    },
    router,
    store,
    render: app => app(App)
});

/* ----------------------------------------
    Set Bugherd
---------------------------------------- */

// import { Bugherd } from '@helpers/Bugherd';
// new Bugherd();